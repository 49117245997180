import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import roomlockIcon from "../../images/hotel/tab/roomlocks.svg";
import cctvIcon from "../../images/hotel/tab/cctv.svg";
import smarttvIcon from "../../images/hotel/tab/smarttv.svg";
import ipphoneIcon from "../../images/hotel/tab/ipphone.svg";

import smartroomIcon from "../../images/hotel/tab/smartroom.svg";
import onlineIcon from "../../images/hotel/tab/online.svg";

export default function SimplifiedDelivery() {

  return (
    <div className="tab-delivery">
      <Container fluid className="p-4">
        <Row>
          <Col>
            <div className="tab-main-title mb-3">
              {"We support and run various auxiliary services over our network"}
            </div>
          </Col>
        </Row>

        <Row className="my-4">
          <Col xs={12} md={3}>
            <div className="p-3">
              <img src={roomlockIcon} alt="Room Locks" className="block-center big-icon mb-4" />
              <div className="my-2 tab-title-big text-center">
                {"Room Locks"}
              </div>
            </div>
          </Col>
          <Col xs={12} md={3}>
            <div className="p-3">
              <img src={cctvIcon} alt="Security CCTV" className="block-center big-icon mb-4" />
              <div className="my-2 tab-title-big text-center">
                {"Security CCTV"}
              </div>
            </div>
          </Col>
          <Col xs={12} md={3}>
            <div className="p-3">
              <img src={smarttvIcon} alt="Smart TV and IPTV" className="block-center big-icon mb-4" />
              <div className="my-2 tab-title text-center">
                {"Smart TV and IPTV"}
              </div>
            </div>
          </Col>
          <Col xs={12} md={3}>
            <div className="p-3">
              <img src={ipphoneIcon} alt="IP-Phone Based Services" className="block-center big-icon mb-4" />
              <div className="my-2 tab-title text-center">
                {"IP-Phone Based Services"}
              </div>
            </div>
          </Col>
        </Row>

        {/* desc */}
        <Row className="my-4">
          <Col>
            <img src={smartroomIcon} alt="Smart room" className="tab-img mr-3" />
            <div className="tab-title">
              {"Smart Room Friendly"}
            </div>
          </Col>
          <Col xs={12} lg={6} xl={8}>
            <div className="tab-desc">
              {"VostroNet's innovative PrivateMesh Technology can facilitate secure and seamless connectivity between devices and appliances. Stay ahead in the market with VostroNet's innovative technology."}
            </div>
          </Col>
        </Row>

        <Row className="my-4">
          <Col>
            <img src={onlineIcon} alt="online" className="tab-img mr-3" />
            <div className="tab-title">
              {"Keeping guests online"}
            </div>
          </Col>
          <Col xs={12} lg={6} xl={8}>
            <div className="tab-desc">
              {"VostroNet's around-the-clock support means hotel guests and management will have someone to call, no matter the time of day."}
            </div>
            <div className="tab-desc my-3">
              {"We monitor all aspects of our - from the fibre to the individual access points - so staff and guests have a single point-of-contact to get connected. No third party involvement."}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}