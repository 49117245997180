import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import malwareNowhereIcon from "../../images/hotel/tab/malware_nowhere_icon.svg";
import personalizedIcon from "../../images/hotel/tab/personalizedcontent_icon.svg";
import simpleLoginIcon from "../../images/hotel/tab/simple_login_icon.svg";
import usefulDataIcon from "../../images/hotel/tab/useful_data_icon.svg";

export default function HotelPlatformTab() {

  return (
    <div className="tab-hotel-platform">
      <Container fluid className="p-4">
        <Row>
          <Col>
            <div className="tab-main-title mb-3">
              {"A Productive, Professional Platform"}
            </div>
            <div className="tab-desc">
              {"Our innovative Q2 software platform is available for hotel management who select VostroNet's hotel Wi-Fi solution. Including advanced functionality such as user heat-mapping, capacity and trends and also a customisable login captive portal."}
            </div>
          </Col>
        </Row>
        {/* desc */}
        <Row className="my-5">
          <Col>
            <img src={simpleLoginIcon} alt="Simple Login" className="tab-img mr-3" />
            <div className="tab-title">
              {"Simple Login"}
            </div>
          </Col>
          <Col xs={12} lg={6} xl={8}>
            <div className="tab-desc">
              {"Guests can connect within 30 seconds of entering their room via VostroNet's hotel platform. Integrating with all major PMS providers, simplifying the check-in process."}
            </div>
          </Col>
        </Row>

        <Row className="my-5">
          <Col>
            <img src={malwareNowhereIcon} alt="Malware Nowhere" className="tab-img mr-3" />
            <div className="tab-title">
              {"Malware Nowhere"}
            </div>
          </Col>
          <Col xs={12} lg={6} xl={8}>
            <div className="tab-desc">
              {"Protection from malware and known virus locations built into the platform, safeguarding guests."}
            </div>
          </Col>
        </Row>

        <Row className="my-5">
          <Col>
            <img src={usefulDataIcon} alt="Useful Data" className="tab-img mr-3" />
            <div className="tab-title">
              {"Useful Data"}
            </div>
          </Col>
          <Col xs={12} lg={6} xl={8}>
            <div className="tab-desc">
              {"Advanced analytics (including aggregated customer demographics and geolocation data) allows management to understand guests further and optimise staff development."}
            </div>
          </Col>
        </Row>

        <Row className="my-5">
          <Col>
            <img src={personalizedIcon} alt="Personalised Content" className="tab-img mr-3" />
            <div className="tab-title">
              {"Personalised Content"}
            </div>
          </Col>
          <Col xs={12} lg={6} xl={8}>
            <div className="tab-desc">
              {"Our platform is easily customisable to suit your hotel branding. Customise the guest Wi-Fi experience via customised landing pages and tailor content by profile, location, date and time."}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}