import React from "react"
import { Container, Row, Col, Tabs, Tab, Button } from "react-bootstrap";

import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/sections/banner"
import Blog from "../components/sections/blogs"
import InformationSection from "../components/sections/informational-section";
import OtherMIESolutions from "../components/sections/hubs/other-MIE-hub";
import FeaturedCaseStudy from "../components/sections/case-study/featured-case-study";

import ourOwnFibreIcon from "../images/rne/info/ownfibre_icon.svg";
import accessibleWifiIcon from "../images/hotel/info/accessible_wifi.svg";
import cleverSoftwareIcon from "../images/hotel/info/clever_software.svg";

import studAccoIcon from "../images/other-mie/student_accom.png";
import buildToRentIcon from "../images/other-mie/buildtorent.png";
import publicWifiIcon from "../images/other-mie/public_wifi.png";
import residentialIcon from "../images/other-mie/residential.png";

import HotelPlatformTab from "../components/tabs/hotel-platform-tab";
import SimplifiedDelivery from "../components/tabs/simplified-delivery";

import bannerImage from "../images/box-headers/hotel.jpg";
import morrowIcon from "../images/mie/case-study/morrow_street.svg";
import bgImg from "../images/headers/subheader_blue.svg";
import {getCategoryId} from "../utils/categories";

const categoryId = getCategoryId("Hotels");

const Hotel = () => (
  <Layout title="Hotel">
    <SEO title="Hotels - Internet and Wi-Fi Platform - VostroNet">
      <meta name="description" content="VostroNet provides high speed internet and wi-fi for Hotels, powered by our innovative Q2 software platform to connect guests and keep guests online." />
    </SEO>
    <Banner subpage bgImg={bgImg} data={{
      title: {
        name: "Hotel Wi-Fi",
      },
      subtitles: [
        {
          name: `Make Wi-Fi an Asset`,
          className: "fw-400",
        },
        {
          name: `Keep guests connected with VostroNet's end-to-end hotel network solution, powered by our innovative hotel platform.`,
          className: "banner-text-small",
        },
      ],
      img: bannerImage,
    }}
      hideScrollButton
      customButtons={(<>
        <Container>
          <Row>
            <Col xs="auto">
              <Button variant="green" className="btn-banner mb-3" rel="noopener" target="_blank" href="https://cdn.vostro.cloud/content/vostronet-datasheet-hotel.pdf">
                <i className="fas fa-file-pdf mr-2" />
                {"Hotel Datasheet"}
              </Button>
            </Col>
            <Col xs="auto">
              <Button variant="outline-dark" className="btn-banner-secondary" rel="noopener" target="_blank" href="https://cdn.vostro.cloud/content/vostronet-solutions-hotel.pdf">
                <i className="fas fa-binoculars mr-2" />
                {"Quick overview"}
              </Button>
            </Col>
          </Row>
        </Container>
      </>)} />
    <div className="general py-5">
      <Container>
        <Row>
          <Col>
            <div className="title mb-3">
              A Comprehensive Solution
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="desc my-4">
              {"End-to-end infrastructure and software solution to provide hotels with the security and simplicity of having a single integrated provider."}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="desc my-4">
              {"VostroNet quality controls every component that is involved in delivery of the Wi-Fi from the wireless signal in the room, the software authenticating the user, the fibre connecting the building and the connection to the content providers. We guarantee performance."}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
    <InformationSection data={{
      title: "Innovation for a smarter hotel",
      subtitle: "By Operating every component of the internet solution, we ensure that guests get online and stay online.",
      subData: [
        {
          title: "Clever software",
          subtitle: "We are a software-defined network, Our Platform integrates with the leading industry systems.",
          img: cleverSoftwareIcon,
        },
        {
          title: "Accessible Wi-Fi",
          subtitle: "A lot goes into our Wi-Fi design. We use heat-mapping and industry best practices to ensure coverage everywhere from the room to the rooftop and everything in-between",
          img: accessibleWifiIcon,
        },
        {
          title: "Our own fibre",
          subtitle: "By owning and operating our own fibre from the data centre to the home, we can ensure performance and quality is maintained at a higher level than others.",
          img: ourOwnFibreIcon,
        },
      ],
    }} />

    <Container className="py-5 tab-section">
      <Row>
        <Col>
          <Tabs defaultActiveKey="hotel-platform" id="SA-tab-section">
            <Tab eventKey="hotel-platform" title="Hotel Platform">
              <HotelPlatformTab />
            </Tab>
            <Tab eventKey="delivery" title="Simplified Delivery">
              <SimplifiedDelivery />
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </Container>
    <FeaturedCaseStudy data={{
      link: "https://cdn.vostro.cloud/content/casestudy-hotel-essence.pdf",
      title: "Featured case studies",
      desc: "We just did something a little exciting down at Morrow Street...",
      img: morrowIcon,
      sideNote: "High-speed hotel wireless and platform for the Essence Suites Taringa, Brisbane.",
    }} />
    <Blog category={categoryId} />
    <OtherMIESolutions data={[
      {
        label: "Public Wi-Fi",
        link: "/public-wifi/",
        img: publicWifiIcon,
      },
      {
        label: "Residential MDU",
        link: "/residential-fibre-network/",
        img: residentialIcon,
      },
      {
        label: "Student Accommodation",
        link: "/student-accommodation/",
        img: studAccoIcon,
      },
      {
        label: "Build-to-Rent",
        link: "/build-to-rent/",
        img: buildToRentIcon,
      },
    ]} />
  </Layout>
)

export default Hotel;
